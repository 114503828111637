import { Link } from "gatsby";
import gsap from "gsap";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import React, { useEffect, useRef, useState } from "react";
import { getNavItems } from "../../../api/nav";
import DarkModeToggle from "../atoms/buttons/darkModeToggle";

gsap.registerPlugin(CSSRulePlugin);

const Layout = ({ title = "", children }) => {
   const [nav, setNav] = useState(false);
   const navFixed = useRef(null);

   let nav_tl = false;

   const initNavTimeline = () => {
      let nav = navFixed.current;
      let overlay = nav.querySelector("#main-nav-overlay"),
         navWrapper = nav.querySelector("#main-nav"),
         navClose = nav.querySelector("#main-nav-close");

      nav_tl = gsap.timeline({ paused: true });
      nav_tl.to(nav, { display: "none", duration: 0 });
      nav_tl.to(overlay, { opacity: "0", duration: 0 });
      nav_tl.to(navWrapper, { translateX: "-100%", duration: 0 });
      nav_tl.to(nav, { display: "flex", duration: 0 });
      nav_tl.to(navClose, {
         opacity: 0,
         translateX: "-12px",
         rotate: -180,
         duration: 0,
      });
      nav_tl.to(overlay, {
         opacity: 1,
         duration: 0.5,
         ease: "Circ.easeInOut",
      });
      nav_tl.to(
         navWrapper,
         {
            translateX: "0%",
            duration: 0.5,
            ease: "Circ.easeInOut",
         },
         "-=0.2"
      );
      nav_tl.to(navClose, {
         opacity: 1,
         translateX: "0px",
         rotate: 0,
         duration: 0.3,
         ease: "Circ.easeInOut",
      });
   };

   useEffect(() => {
      initNavTimeline();
   }, [navFixed]);

   useEffect(() => {
      if (typeof window !== "undefined") {
         document.querySelectorAll(".dashboard-container").forEach((el) => {
            gsap.fromTo(el, { opacity: 0, duration: 0 }, { opacity: 1, duration: 0.8 });
         });
      }
   }, []);

   const handleOpenNav = () => {
      if (!nav_tl) initNavTimeline();

      if (nav_tl.reversed()) {
         nav_tl.reverse();
         nav_tl.play();
      } else nav_tl.play();
   };

   const handleCloseNav = () => {
      if (nav_tl) {
         nav_tl.play();
         nav_tl.reverse();
      }
   };

   return (
      <div className="h-screen flex overflow-hidden bg-gray-100 dark:bg-gray-800 transition-colors duration-150 ease-in">
         <div className="hidden absolute top-0 bottom-0 left-0 md:static md:flex md:flex-shrink-0 z-40 w-full md:w-auto" ref={navFixed}>
            <div className={`fixed inset-0 z-20 opacity-100 md:hidden`} id="main-nav-overlay" onClick={() => handleCloseNav()}>
               <div className="absolute inset-0 bg-gray-600 dark:bg-black opacity-75" />
            </div>

            <div className="relative z-30 flex flex-col max-w-xs w-full md:max-w-none md:w-64" id="main-nav">
               <div className="absolute top-0 right-0 -mr-12 pt-2 md:hidden">
                  <button className={`ml-1 items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white ${nav ? `flex` : `flex`}`} onClick={() => handleCloseNav()} id="main-nav-close">
                     <span className="sr-only">Close sidebar</span>

                     <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                     </svg>
                  </button>
               </div>

               <div className="flex flex-col h-0 flex-1 border-r border-gray-200 bg-white dark:bg-gray-900 dark:border-gray-700 transition-colors duration-150 ease-in" id="main-nav">
                  <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                     <div className="flex items-center flex-shrink-0 px-4 text-gray-900 dark:text-white transition-colors duration-150 ease-in">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 386 480" fill="none" width="50">
                           <path stroke="currentColor" strokeLinejoin="bevel" strokeWidth="6" d="M291.834 274.895v33.948H72.007V3.829h127.166S327.809.818 327.809 90.265s-81.383 93.95-81.383 93.95 136.185 23.591 136.185 149.464c0 125.874-112.554 141.009-112.554 141.009s-110.254 11.633-170.6-21.209c-60.346-32.842-60.12-144.636-60.12-144.636H.61"></path>
                           <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="bevel" strokeWidth="6" d="M145.611 19.73V235.5l-60 60.232"></path>
                           <path stroke="currentColor" strokeWidth="6" d="M85.611 110.111l44.962-44.962M85.611 178.111l44.962-44.962M85.611 246.111l44.962-44.962m-44.962 68.813L130.573 225M85.611 87.962L130.573 43M85.611 155.962L130.573 111M85.611 223.962L130.573 179M85.611 64.962L130.573 20M85.611 132.962L130.573 88M85.611 200.962L130.573 156"></path>
                        </svg>
                        <p className="sr-only">Logan Blangenois workspace</p>
                     </div>
                     <nav className="mt-5 flex-1 px-2 bg-white dark:bg-gray-900 space-y-1 transition-colors duration-150 ease-in">
                        {getNavItems.map((item, index) => {
                           return (
                              <Link to={item.link} className="text-gray-900 dark:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md hover:text-gray-900 dark:hover:text-white hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors duration-150 ease-in" activeClassName={`bg-gray-100 dark:bg-gray-800`} key={`main-nav-${index}`}>
                                 <span
                                    className="block w-5 h-5 mr-2"
                                    dangerouslySetInnerHTML={{
                                       __html: item.icon,
                                    }}
                                 />
                                 <span>{item.title}</span>
                              </Link>
                           );
                        })}
                     </nav>
                  </div>
                  <div className="flex-shrink-0 flex border-t border-gray-200 dark:border-gray-700 p-4">
                     <DarkModeToggle />
                  </div>
               </div>
            </div>
            <div className="flex-shrink-0 w-14">{/* // FORCE CLOSE */}</div>
         </div>
         <div className="flex flex-col w-0 flex-1 overflow-hidden">
            <div className="md:hidden px-4 py-4">
               <button className="ml-auto flex items-center justify-center rounded-md text-gray-900 dark:text-white focus:outline-none" onClick={() => handleOpenNav()}>
                  <span className="sr-only">Open sidebar</span>
                  <svg className="w-8 h-auto" viewBox="0 0 400 329" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <line x1="200" y1="25" x2="400" y2="25" stroke="currentColor" strokeWidth="50" />
                     <line y1="304" x2="200" y2="304" stroke="currentColor" strokeWidth="50" />
                     <line x1="-2.18557e-06" y1="162" x2="400" y2="162" stroke="currentColor" strokeWidth="50" />
                  </svg>
               </button>
            </div>
            <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabIndex={0}>
               <div className="pt-0 pb-6 md:py-6">
                  <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                     <h1 className="border-b border-gray-200 dark:border-gray-600 pb-5 mb-5 text-2xl font-semibold text-gray-900 dark:text-white">{title}</h1>
                  </div>
                  <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                     {/* Replace with your content */}
                     <div className="py-4 dashboard-container">{children}</div>

                     {/* /End replace */}
                  </div>
               </div>
            </main>
         </div>
      </div>
   );
};

export default Layout;
