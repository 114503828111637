import React, { useState } from "react";

const DarkModeToggle = () => {
   const [darkMode, setDarkMode] = useState(
      typeof window !== "undefined"
         ? document.documentElement.classList.contains("dark")
         : false
   );

   const handleLightMode = () => {
      if (typeof window !== "undefined") {
         localStorage.theme = "light";
         setDarkMode(false);
         document.documentElement.classList.remove("dark");
      }
   };

   const handleDarkMode = () => {
      if (typeof window !== "undefined") {
         localStorage.theme = "dark";
         setDarkMode(true);
         document.documentElement.classList.add("dark");
      }
   };

   return (
      <div className="dark:text-white leading-none">
         <button
            onClick={() => (darkMode ? handleLightMode() : handleDarkMode())}
         >
            {darkMode ? (
               <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  className="w-8 h-8"
                  stroke="currentColor"
               >
                  <path
                     strokeLinecap="round"
                     strokeLinejoin="round"
                     strokeWidth={2}
                     d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
                  />
               </svg>
            ) : (
               <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  className="w-8 h-8"
                  stroke="currentColor"
               >
                  <path
                     strokeLinecap="round"
                     strokeLinejoin="round"
                     strokeWidth={2}
                     d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                  />
               </svg>
            )}
         </button>
      </div>
   );
};

export default DarkModeToggle;
